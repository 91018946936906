// In src/prismic-configuration.js
export default {
  endPoint: 'https://oliviatapper.cdn.prismic.io/api/v2',
  linkResolver: doc => {
    if (doc.isBroken) {
      return '/not-found';
    }

    switch (doc.type) {
      case 'home':
        return '/';
      case 'case_study':
        return `/work/${doc.uid}`;
      default:
        return '/not-found';
    }
  }
};
