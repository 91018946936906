import { useState, useEffect } from 'react';
import PrismicServices from '../services/Prismic';

function useSingleton(docId, cache) {
  const [document, setDocument] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (cache) {
      setLoading(false);
      setDocument(cache);
    } else {
      PrismicServices.getDocument(docId).then(({ doc }) => {
        setDocument(doc);
        setLoading(false);
      });
    }
  }, [docId, cache]);

  return [document, loading];
}

function useRepeatable(type) {
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    PrismicServices.getRepeatable(type).then(({ results }) => {
      setResults(results);
      setLoading(false);
    });
  }, [type]);
  return [results, loading];
}

function useCaseStudy(uuid, caseStudy) {
  const [document, setResults] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (caseStudy) {
      console.log(`already have data for ${uuid}`);
      setResults(caseStudy);
      return setLoading(false);
    } else {
      PrismicServices.getCaseStudy(uuid)
        .then(({ doc }) => {
          console.log(`received new data from ${uuid}`);
          // const newDocument = document;
          // newDocument[uuid] = doc.data;
          setResults(doc.data);
          setLoading(false);
        })
        .catch(() => {
          console.log(`failed to receive data from ${uuid}`);
          // const newDocument = document;
          // newDocument[uuid] = null;
          setResults(null);
          setLoading(false);
        });
      return setLoading(true);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [uuid]);
  return [document, loading];
}

function useCaseStudies(caseStudies) {
  const [document, setResults] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (Object.entries(caseStudies).length !== 0 && caseStudies.contructor !== Object) {
      // console.log(`already have caseStudies`);
      setResults(caseStudies);
      setLoading(false);
    } else {
      PrismicServices.getCaseStudies()
        .then(({ results }) => {
          // console.log(`received new caseStudies`);
          const newObj = {};
          results.forEach(caseStudy => {
            const { data } = caseStudy;
            data.uid = caseStudy.uid;
            newObj[caseStudy.uid] = data;
          });
          setResults(newObj);
          setLoading(false);
        })
        .catch(() => {
          // console.log(`failed to receive caseStudies`);
          setResults({});
          setLoading(false);
        });
    }
  }, [caseStudies]);
  return [document, loading];
}

function useCaseStudyUUIDs(uuid) {
  const [uuids, setResults] = useState({ uuids: [] });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    PrismicServices.getSiblingCaseStudyUUIDs(uuid).then(({ uuids }) => {
      setResults({ uuids });
      setLoading(false);
    });
  }, [uuid]);
  return [uuids, loading];
}

export { useSingleton, useRepeatable, useCaseStudy, useCaseStudyUUIDs, useCaseStudies };
