/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useState } from 'react';
import { useSingleton } from '../../hooks';
import { RichText } from 'prismic-reactjs';
import { withResizeDetector } from 'react-resize-detector';
import NavMenu from '../NavMenu';
import ResponsiveNavMenu from '../ResponsiveNavMenu';
import './styles.scss';
import openMenuIcon from '../../assets/images/menu-open-dk.svg';
import openMenuIconWhite from '../../assets/images/menu-open-wh.svg';
import Fade from 'react-reveal/Fade';

const header = css`
  position: fixed;
  z-index: 5;
`;

const responsiveHeader = css`
  position: fixed;
  z-index: 100;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
`;

const titleStyle = css`
  color: #1d1d1d;
  font-weight: 400;
  letter-spacing: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;
`;

const menu = css`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const responsiveBreakpoint = 1024;

export default withResizeDetector(({ width, url, response, setResponse }) => {
  const [{ data }, loading] = useSingleton('home', response['home']);
  const [isHidden, setHidden] = useState(true);

  if (loading) return <div style={{ display: 'none' }}></div>;

  if (!response['home']) {
    const newResponse = response;
    newResponse['home'] = data;
    setResponse(newResponse);
  }

  const { title } = response['home'];
  const menuIconRef = React.createRef();
  const headerRef = React.createRef();

  const render = () => (
    <header css={header} ref={headerRef}>
      <section css={menu} className='menu'>
        <div></div>
        <div css={titleStyle} className='title'>
          {url === '/' ? RichText.asText(title) : ''}
        </div>
        <div></div>
        <NavMenu url={url} reverse={url === '/about'} />
        <div></div>
      </section>
    </header>
  );

  const renderResponsive = () => (
    <header css={responsiveHeader}>
      <ResponsiveNavMenu
        menuIconRef={menuIconRef}
        url={url}
        isHidden={isHidden}
        setHidden={setHidden}
      />
      <Fade>
        <div
          className='open-icon-container'
          style={{
            cursor: 'pointer',
            color: `${url === '/about' ? '#fff' : '#000'}`,
            display: `${isHidden ? '' : 'none'}`
          }}
          ref={menuIconRef}
          onClick={() => {
            setHidden(false);
          }}
        >
          <img
            src={url === '/about' ? openMenuIconWhite : openMenuIcon}
            className='open-icon'
            alt='open menu'
          />
        </div>
      </Fade>
    </header>
  );

  return width >= responsiveBreakpoint ? render() : renderResponsive();
});
