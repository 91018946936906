import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../components/Loader';

export default [
  {
    name: 'Home',
    path: '/',
    exact: true,
    component: Loadable({
      loader: () => import('../Home'),
      loading() {
        return <Loader />;
      }
    })
  },
  {
    name: 'About',
    path: '/about',
    component: Loadable({
      loader: () => import('../About'),
      loading() {
        return <Loader />;
      }
    })
  },
  {
    name: 'Work',
    path: '/work',
    component: Loadable({
      loader: () => import('../Work'),
      loading() {
        return <Loader />;
      }
    })
  },
  {
    name: 'Case Study',
    path: '/case-study/:uuid',
    component: Loadable({
      loader: () => import('../CaseStudy'),
      loading() {
        return <Loader />;
      }
    })
  }
];
