import Prismic from 'prismic-javascript';
import Config from './prismic-configuration.js';

export default {
  getDocument: async docId =>
    new Promise((resolve, reject) => {
      Prismic.api(Config.endPoint).then(api => {
        api.getSingle(docId).then(doc => {
          if (doc) {
            resolve({ doc });
          } else {
            reject({ doc: {} });
          }
        });
      });
    }),
  getRepeatable: async type =>
    new Promise((resolve, reject) => {
      Prismic.api(Config.endPoint).then(api => {
        api.query(Prismic.Predicates.at('document.type', type)).then(response => {
          if (response) {
            resolve({ results: response.results });
          } else {
            reject({ results: [] });
          }
        });
      });
    }),
  getCaseStudy: async docId =>
    new Promise((resolve, reject) => {
      Prismic.api(Config.endPoint).then(api => {
        api.getByUID('case_study', docId).then(doc => {
          if (doc) {
            resolve({ doc });
          } else {
            reject({ doc: {} });
          }
        });
      });
    }),
  getCaseStudies: async () =>
    new Promise((resolve, reject) => {
      Prismic.api(Config.endPoint).then(api => {
        api
          .query(Prismic.Predicates.at('document.type', 'case_study'))
          .then(({ results }) => {
            resolve({ results });
          })
          .catch(() => {
            reject({ results: [] });
          });
      });
    })
};
