/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { NavLink } from 'react-router-dom';
import './ResponsiveNavMenu.scss';
import closeMenuIcon from '../../assets/images/menu-close-dk.svg';
import { withRouter } from 'react-router-dom';

const responsiveLink = css`
  margin: 20px;
  text-decoration: none;
  color: #000;
  font-size: 2rem;
`;

export default withRouter(({ menuRef, isHidden, setHidden, location }) => {
  const closeMenu = () => {
    setHidden(true);
  };

  return (
    // <div className={`mobile-nav ${isHidden ? 'hidden' : 'visible'}`}>
    <section className={`mobile-menu  ${isHidden ? 'collapsed' : 'expanded'}`} ref={menuRef}>
      <div style={{ margin: '10px 0' }}>
        <NavLink to='/' css={responsiveLink} onClick={closeMenu}>
          Home
        </NavLink>
      </div>
      <div style={{ margin: '10px 0' }}>
        <NavLink to='/work' css={responsiveLink} onClick={closeMenu}>
          Work
        </NavLink>
      </div>
      <div style={{ margin: '10px 0' }}>
        <NavLink to='/about' css={responsiveLink} onClick={closeMenu}>
          About
        </NavLink>
      </div>
      <div onClick={closeMenu}>
        <img src={closeMenuIcon} className='close-icon' alt='close' />
      </div>
    </section>
    // </div>
  );
});
