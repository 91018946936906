/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { NavLink } from 'react-router-dom';
import './navmenu.scss';

const nav = css`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 0;
`;

const MenuLink = ({ to, exact = false, reverse, text, isActive, inactiveClass }) => (
  <NavLink
    to={to}
    exact={exact}
    activeClassName={`active-menu-link ${reverse ? 'active-menu-link-reverse' : ''}`}
    className={`menu-link ${
      isActive ? 'active-menu-link' : inactiveClass !== '' ? inactiveClass : ''
    } ${reverse ? 'active-menu-link-reverse' : ''}`}
  >
    {text}
    {isActive && <div className={`${reverse ? 'reverse-underline' : 'underline'}`}></div>}
  </NavLink>
);

export default ({ reverse, url }) => {
  const menus = ['/', '/work', '/about'];
  let currentIndex = menus.indexOf(url);
  if (url.includes('/case-study')) {
    currentIndex = 1;
  }
  const inactiveClasses = [];
  menus.forEach((menu, index) => {
    if (index === currentIndex) {
      inactiveClasses.push('');
    } else {
      if (index === 1 && url.includes('/case-study')) {
        inactiveClasses.push('');
      } else {
        if (index < currentIndex) {
          if (
            inactiveClasses.indexOf('inactive-menu-start') >= 0 ||
            inactiveClasses.indexOf('inactive-menu-center') >= 0
          ) {
            inactiveClasses.push('inactive-menu-start');
          } else {
            if (currentIndex === 1) {
              inactiveClasses.push('inactive-menu-start');
            } else {
              inactiveClasses.push('inactive-menu-center');
            }
          }
        } else {
          if (
            inactiveClasses.indexOf('inactive-menu-start') >= 0 ||
            inactiveClasses.indexOf('inactive-menu-center') >= 0
          ) {
            inactiveClasses.push('inactive-menu-center');
          } else {
            inactiveClasses.push('inactive-menu-start');
          }
        }
      }
    }
  });

  return (
    <nav css={nav}>
      <MenuLink
        to='/'
        exact={true}
        text='Home'
        reverse={reverse}
        isActive={url === '/'}
        inactiveClass={inactiveClasses[0]}
      />
      <MenuLink
        to='/work'
        text='Work'
        reverse={reverse}
        isActive={url === '/work' || url.includes('/case-study')}
        inactiveClass={inactiveClasses[1]}
      />
      <MenuLink
        to='/about'
        text='About'
        reverse={reverse}
        isActive={url === '/about'}
        inactiveClass={inactiveClasses[2]}
      />
    </nav>
  );
};
