import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import './loader.scss';

export default withRouter(({ location }) => {
  const { pathname } = location;
  const onAboutPage = pathname === '/about';
  const onCaseStudyPage = pathname.includes('/case-study');
  useEffect(() => {
    document.body.classList.add('noscroll');
    return document.body.classList.remove('noscroll');
  }, []);

  let className = 'loader';
  if (onAboutPage) {
    className += ' background';
  }
  if (onCaseStudyPage) {
    className += ' nodelay';
  }

  return (
    <div className={className}>
      <svg
        width='27px'
        height='43px'
        viewBox='0 0 27 43'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <title>icon/lightning</title>
        <g
          id='loader'
          stroke='none'
          strokeWidth='1'
          fill='none'
          fillRule='evenodd'
          strokeLinejoin='round'
        >
          <g
            id='iPhone-8-Copy-2'
            transform='translate(-174.000000, -68.000000)'
            fillRule='nonzero'
            stroke={onAboutPage ? '#fff' : '#000'}
          >
            <g id='icon/lightning' transform='translate(175.000000, 69.000000)'>
              <path
                d='M14.7287963,15.1191751 L25.3563125,15.1191245 L-0.516636657,43.1480018 L6.90830779,22.4644402 L0.0471740438,22.4644402 L9.2329052,-0.5 L21.5621427,-0.5 L14.7287963,15.1191751 Z'
                id='Path'
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
});
