import React, { useState } from 'react';
import Loader from './components/Loader';
import Routes from './pages/navigation';
import Header from './components/Header';
import Greeting from './components/Greeting';
import MainContainer from './components/Layout/MainContainer';
import { ParallaxProvider } from 'react-scroll-parallax';
import { withRouter } from 'react-router-dom';

function App(props) {
  const isMatch = props.match.isExact;
  const pathname = props.location.pathname;
  const haveParam = props.location.search !== '' ? true : false;
  const [url, setUrl] = useState(pathname);
  const [greetingLoaded, setGreetingLoaded] = useState(!haveParam);
  // const [greetingLoaded, setGreetingLoaded] = useState(true);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [startAnimation, setStartAnimation] = useState(!haveParam);
  const [response, setResponse] = useState({});
  const [caseStudies, setCaseStudies] = useState({});

  return (
    <ParallaxProvider>
      <MainContainer>
        {loaderVisible && (
          <Loader loaderVisible={loaderVisible} setLoaderVisible={setLoaderVisible} />
        )}
        {!greetingLoaded && isMatch && (
          <Greeting
            greetingLoaded={greetingLoaded}
            setGreetingLoaded={setGreetingLoaded}
            setStartAnimation={setStartAnimation}
            response={response}
            setResponse={setResponse}
          />
        )}
        <Header
          url={url}
          loaderVisible={loaderVisible}
          setLoaderVisible={setLoaderVisible}
          response={response}
          setResponse={setResponse}
        />
        <Routes
          setUrl={setUrl}
          loaderVisible={loaderVisible}
          setLoaderVisible={setLoaderVisible}
          greetingLoaded={greetingLoaded}
          startAnimation={startAnimation}
          response={response}
          setResponse={setResponse}
          caseStudies={caseStudies}
          setCaseStudies={setCaseStudies}
        />
      </MainContainer>
    </ParallaxProvider>
  );
}

export default withRouter(App);
