import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from './routes';
import NotFound from '../../components/NotFound';

export default ({ ...props }) => {
  return (
    <Switch>
      {routes.map(({ path, component: Component, exact }) => (
        <Route
          path={path}
          render={routeProps => {
            props.setUrl(routeProps.match.url);
            return (
              <Component
                {...props}
                {...routeProps}
                key={routeProps.match.url.includes('/case-study/') ? routeProps.match.url : ''}
              />
            );
          }}
          exact={exact}
          key={`path-id-${path}`}
        />
      ))}
      <Route component={NotFound} />
    </Switch>
  );
};
