import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSingleton } from '../../hooks';
import Typist from 'react-typist';
import logo from '../../assets/images/lightning-white.svg';
// import livLogo from '../../assets/images/liv-logo.svg';

import './greeting.scss';

export default withRouter(
  ({ location, match, setGreetingLoaded, setStartAnimation, response, setResponse }) => {
    const [{ data }, loading] = useSingleton('greetings', response['greetings']);
    const [startFade, setStartFade] = useState(false);

    if (!response['greetings']) {
      const newResponse = response;
      newResponse['greetings'] = data;
      setResponse(newResponse);
    }

    const { isExact } = match;
    if (loading || !isExact) return <div className='greeting'></div>;

    if (isExact && !document.body.classList.contains('noscroll')) {
      document.body.classList.add('noscroll');
    }

    const { company_greetings } = response['greetings'];
    const greetingsMap = {};
    company_greetings.map(company => {
      return (greetingsMap[company.company_name] = {
        name: company.company_name,
        image: company.company_logo,
        salutation: company.salutation,
        message: company.message
      });
    });

    const params = new URLSearchParams(location.search);
    const companyURL = params.get('hi');
    const companyLogo = greetingsMap[companyURL]?.image?.url || greetingsMap['default']?.image?.url;
    const companySalutation =
      greetingsMap[companyURL]?.salutation || greetingsMap['default']?.salutation;
    const companyMessage = greetingsMap[companyURL]?.message || greetingsMap['default']?.message;

    return (
      <div className={`greeting ${startFade ? 'animated' : ''}`}>
        <div className='logo'>
          <img src={logo} alt='Lightning Logo' />
        </div>
        <div className='greeting-logo'>
          {companyLogo && <img src={companyLogo} alt={companyLogo.alt} />}
        </div>
        <div className='greeting-content'>
          <Typist
            cursor={{ show: false }}
            avgTypingDelay={50}
            onTypingDone={() => {
              setTimeout(() => {
                setStartFade(true);
              }, 300);
              setTimeout(() => {
                setStartAnimation(true);
                setGreetingLoaded(true);
                document.body.classList.remove('noscroll');
              }, 500);
            }}
          >
            <Typist.Delay ms={companyLogo ? 3500 : 2000} />
            <div>{companySalutation}</div>
            <Typist.Delay ms={500} />
            <div className='greeting-text'>{companyMessage}</div>
          </Typist>
        </div>
      </div>
    );
  }
);
